import { Permission } from 'types/shared/permissions';

import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import TerminalTwoToneIcon from '@mui/icons-material/TerminalTwoTone';
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import FormatListNumberedTwoToneIcon from '@mui/icons-material/FormatListNumberedTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  permission?: Permission;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  permission?: Permission;
}

// @ts-ignore
const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      // Remove for now. Not 100% necessary for MVP.
      // {
      //   name: 'Overview',
      //   badgeTooltip: 'Overview',
      //   icon: DashboardTwoToneIcon,
      //   link: '/app/dashboard'
      // },
      {
        name: 'Applications',
        badgeTooltip: 'Applications',
        icon: InboxTwoToneIcon,
        link: '/app/applications'
      },
      {
        name: 'Positions',
        badgeTooltip: 'Positions',
        icon: WorkTwoToneIcon,
        link: '/app/positions'
      },
      {
        name: 'Workflows',
        badgeTooltip: 'Workflows',
        icon: FormatListNumberedTwoToneIcon,
        link: '/app/hiring-workflows'
      },
      {
        name: 'View Site',
        badgeTooltip: 'View Site',
        icon: StorefrontTwoToneIcon,
        link: '/'
      }
    ]
  },
  {
    heading: 'Management',
    permission: { feature: 'BASE_MANAGEMENT', action: 'VIEW' },
    items: [
      {
        permission: { feature: 'USER_MANAGEMENT', action: 'VIEW' },
        name: 'Users',
        badgeTooltip: 'Manage Users',
        icon: ManageAccountsTwoToneIcon,
        items: [
          {
            name: 'Manage users',
            link: '/app/management/users'
          },
          {
            permission: { feature: 'AUDIT_LOG', action: 'VIEW' },
            name: 'Audit logs',
            link: '/app/management/audit'
          }
        ]
      },
      {
        permission: { feature: 'WORKSPACE_MANAGEMENT', action: 'VIEW' },
        name: 'Workspace Settings',
        badgeTooltip: 'Workspace Settings',
        icon: SettingsApplicationsTwoToneIcon,
        items: [
          {
            name: 'Contact Settings',
            link: '/app/management/workspace/general'
          }
        ]
      },
      {
        permission: { feature: 'JOB_SITE_CONTENT', action: 'UPDATE' },
        name: 'Hiring Portal',
        badgeTooltip: 'Hiring Portal',
        icon: AutoAwesomeTwoToneIcon,
        items: [
          {
            name: 'Pages',
            link: '/app/management/jobSite/pages'
          },
          {
            name: 'Site Settings',
            link: '/app/management/workspace/site-settings'
          }
        ]
      }
    ]
  },
  {
    heading: 'Help',
    items: [
      {
        name: 'User Guide',
        badgeTooltip: 'User Guide',
        icon: MenuBookTwoToneIcon,
        link: '/app/docs'
      },
      {
        name: 'Support',
        badgeTooltip: 'Support',
        icon: SupportTwoToneIcon,
        link: '/app/help/support'
      }
    ]
  },
  {
    heading: 'Development',
    permission: { feature: 'DEVELOPMENT', action: 'VIEW' },
    items: [
      {
        permission: { feature: 'DEVELOPMENT', action: 'VIEW' },
        name: 'Utils',
        badgeTooltip: 'Utils',
        icon: TerminalTwoToneIcon,
        link: '/app/dev/utils'
      }
    ]
  }
];

export default menuItems;
