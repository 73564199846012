import { FC, ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';
import { Header as SiteHeader } from 'layouts/JobSiteLayout/Header';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Footer from 'layouts/JobSiteLayout/Footer';
import { EnvironmentContext, EnvironmentName } from 'contexts/EnvironmentContext';
import DemoBanner from 'components/common/DemoBanner';

interface JobSiteLayoutProps {
  children?: ReactNode;
}

const Wrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);

const Header = styled(SiteHeader)`
  z-index: 5;
`;

const MainContent = styled(Box)(
  ({ theme }) => `
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(8)};
    min-height: calc(100vh - 55px);
  `
);

const JobSiteLayout: FC<JobSiteLayoutProps> = ({ children }) => {
  const { environmentName } = useContext(EnvironmentContext);

  return (
    <Wrapper>
      <Header />
      <MainContent id="main-content">
        {environmentName === EnvironmentName.DEMO && <DemoBanner />}
        <>{children || <Outlet />}</>
      </MainContent>
      <Footer />
    </Wrapper>
  );
};

JobSiteLayout.propTypes = {
  children: PropTypes.node
};

export default JobSiteLayout;
