import { createContext, useCallback, useEffect, useState } from 'react';
import useRefMounted from 'hooks/useRefMounted';
import { checkDomain, getPublicTenantConfig } from 'api/public/tenantConfig';
import { PublicTenantConfig } from 'types/shared/api/tenantConfig';

type EnvironmentContextType = {
  environmentName: string;
  tenantId: string;
  tenantName: string;
  publicTenantConfig: PublicTenantConfig;
  initialised: boolean;
};

export enum EnvironmentName {
  DEMO = 'demo',
  DEVELOP = 'develop',
  PROD = 'prod',
  LOCAL = 'local'
}

export const EnvironmentContext = createContext<EnvironmentContextType>({} as EnvironmentContextType);

export const EnvironmentProvider = ({ children }) => {
  const environmentName = process.env.REACT_APP_ENV;
  const redirectUrl = process.env.REACT_APP_UNKNOWN_TENANT_REDIRECT_URL;

  const [tenantName, setTenantName] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const [initialised, setInitialised] = useState<boolean>(false);
  const [publicTenantConfig, setPublicTenantConfig] = useState<PublicTenantConfig>();

  const isMountedRef = useRefMounted();

  // Use the domain name the user entered to determine if there is a tenant associated with it. If so,
  // then initialise the app with that tenant. Otherwise, redirect to the main sales site.
  const initialiseEnvironment = useCallback(async () => {
    try {
      const currentAddress = window.location.href;
      const checkDomainResponse = await checkDomain(currentAddress);

      // Tenant does not exist
      if (checkDomainResponse.status === 404) {
        window.location.href = redirectUrl;
        return;
      }

      // Happy path, the tenant exists
      if (checkDomainResponse.data && checkDomainResponse.status === 200) {
        const receivedTenantId = checkDomainResponse.data;
        setTenantId(receivedTenantId);

        // Fetch the config associated with the tenant
        const tenantConfigResponse = await getPublicTenantConfig(receivedTenantId);
        if (tenantConfigResponse.data && checkDomainResponse.status === 200) {
          setPublicTenantConfig(tenantConfigResponse.data);
          setTenantName(tenantConfigResponse.data.companyDetails.name);
          setInitialised(true);
        }
        return;
      }

      // Any error, redirect to the sales site.
    } catch (err) {
      console.error(err);
      window.location.href = redirectUrl;
    }
  }, [isMountedRef]);

  useEffect(() => {
    initialiseEnvironment();
  }, [isMountedRef]);

  return (
    <EnvironmentContext.Provider value={{ environmentName, tenantId, tenantName, publicTenantConfig, initialised }}>
      {children}
    </EnvironmentContext.Provider>
  );
};
