import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import SuspenseLoader from 'components/core/SuspenseLoader';
import Authorised from 'components/core/Routes/Authorised';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('pages/management/Users/Management')));
const WorkspaceGeneral = Loader(lazy(() => import('pages/management/Workspace/General')));
const WorkspaceBrand = Loader(lazy(() => import('pages/management/Workspace/SiteSettings')));
const JobSitePageList = Loader(lazy(() => import('pages/management/JobSite/index')));
const JobSiteViewPage = Loader(lazy(() => import('pages/management/JobSite/single')));
const JobSiteCreatePage = Loader(lazy(() => import('pages/management/JobSite/create')));
const JobSiteEditPage = Loader(lazy(() => import('pages/management/JobSite/edit')));

const Audit = Loader(lazy(() => import('pages/management/Users/Audit')));

const managementRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    element: (
      <Authorised requiredPermission={{ feature: 'USER_MANAGEMENT', action: 'VIEW' }}>
        <Outlet />
      </Authorised>
    ),
    children: [
      {
        path: '',
        element: <Users />
      }
    ]
  },
  {
    path: 'audit',
    element: (
      <Authorised requiredPermission={{ feature: 'AUDIT_LOG', action: 'VIEW' }}>
        <Outlet />
      </Authorised>
    ),
    children: [
      {
        path: '',
        element: <Audit />
      }
    ]
  },
  {
    path: 'workspace',
    element: (
      <Authorised requiredPermission={{ feature: 'WORKSPACE_MANAGEMENT', action: 'VIEW' }}>
        <Outlet />
      </Authorised>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="general" replace />
      },
      {
        path: 'general',
        element: <WorkspaceGeneral />
      },
      {
        path: 'site-settings',
        element: <WorkspaceBrand />
      }
    ]
  },
  {
    path: 'jobSite',
    element: (
      <Authorised requiredPermission={{ feature: 'JOB_SITE_CONTENT', action: 'VIEW' }}>
        <Outlet />
      </Authorised>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="pages" replace />
      },
      {
        path: 'pages',
        element: <JobSitePageList />
      },
      {
        path: 'pages/view/:pageId',
        element: <JobSiteViewPage />
      },
      {
        path: 'pages/create',
        element: <JobSiteCreatePage />
      },
      {
        path: 'pages/edit/:pageId',
        element: <JobSiteEditPage />
      }
    ]
  }
];

export default managementRoutes;
