import { BACKEND_API, getUrl, postUrl } from 'helpers/http';

export const getPublicTenantConfig = (tenantId: string) => {
  return getUrl(BACKEND_API.PRISMA_PUBLIC, `/${tenantId}/tenantConfig`);
};

export const getFeatureFlags = (tenantId: string) => {
  return getUrl(BACKEND_API.PRISMA_PUBLIC, `/${tenantId}/featureFlags`);
};

export const checkDomain = (domain: string) => {
  return postUrl(BACKEND_API.PRISMA_BOOT, `/checkDomain`, {
    domain: domain
  });
};
